import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/globle.scss';
import footers from './components/Footers';

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.component('footers', footers)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
