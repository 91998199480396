import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/Main.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: { title: '高仕登（河南）教育科技官网' }
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('../views/News.vue'),
        meta: { title: '新闻资讯' }
      },
      {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/Notice.vue'),
        meta: { title: '信息公告' }
      },
      {
        path: '/contcat',
        name: 'contcat',
        component: () => import('../views/Contcat.vue'),
        meta: { title: '联系我们' }
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Profile.vue'),
        meta: { title: '公司简介' }
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  document.title = to.meta.title
})

export default router
